import {
  BottomBar,
  ErrorScreen,
  Explorer,
  InstructionExplorer,
  Login,
  NextLoadingTransition,
  PersistLogin,
  ProjectExplorer,
  RequireAuth,
  Unauthorized,
  UserProfile,
} from '@assemblio/frontend/components';
import { usePreventZoom, useUserProfile } from '@assemblio/frontend/hooks';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { enableMapSet } from 'immer';
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { Assembler } from './pages/Assembler';
import classes from './Viewer.module.scss';
import { viewerTheme } from '../theme/Viewer.theme';
import { ThemeVariablesResolver } from '../theme/CSSVariables';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import React from 'react';

const queryClient = new QueryClient();
enableMapSet();

function ViewerWithProvider() {
  usePreventZoom();

  const userProfileOpened = useUserProfile();

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <SentryRoutes>
      <Route path="/login" element={<Login />} />
      <Route path="/unauthorized" element={<Unauthorized />} />

      {/* protected routes */}
      <Route element={<PersistLogin />}>
        <Route
          element={
            <RequireAuth allowedRoles={['editor', 'viewer', 'manager']} />
          }
        >
          <Route
            element={
              <div id="container" className={classes.container}>
                <div id="content" className={classes.content}>
                  <Outlet />
                  <UserProfile opened={userProfileOpened} />
                </div>
                <div id="bottom" className={classes.footer}>
                  <BottomBar showCenterinExplorer={false} />
                </div>
              </div>
            }
          >
            <Route path="/explorer" element={<Explorer />}>
              <Route index element={<ProjectExplorer />} />
              <Route path=":folderId" element={<ProjectExplorer />} />
              <Route
                path="project/:projectId"
                element={<InstructionExplorer />}
              />
            </Route>
            <Route
              path="/assembler/:instructionId"
              element={<NextLoadingTransition component={<Assembler />} />}
            />
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<Navigate replace to="/explorer" />} />
    </SentryRoutes>
  );
}

export function Viewer() {
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider
        theme={viewerTheme}
        cssVariablesResolver={ThemeVariablesResolver}
        defaultColorScheme={'dark'}
      >
        <Sentry.ErrorBoundary fallback={<ErrorScreen />}>
          <Notifications />
          <Router>
            <ReactQueryDevtools initialIsOpen={false} />
            <ViewerWithProvider />
          </Router>
        </Sentry.ErrorBoundary>
      </MantineProvider>
    </QueryClientProvider>
  );
}
