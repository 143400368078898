import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './main.scss';
import './fonts.css';
import { Viewer } from './lib/Viewer';
import { initSentry } from './sentry-viewer.helper';

initSentry();
const element = document.getElementById('root');
if (element) {
  const root = createRoot(element);

  root.render(
    <StrictMode>
      <Viewer />
    </StrictMode>
  );
}
