import { CSSVariablesResolver, rgba } from '@mantine/core';
export const ThemeVariablesResolver: CSSVariablesResolver = (theme) => ({
  variables: {
    '--mantine-color-editor-white_10': theme.other.color.editor.white_10,
    '--mantine-color-editor-white_20': theme.other.color.editor.white_20,
    '--mantine-color-editor-black_10': theme.other.color.editor.black_10,
    '--mantine-color-editor-black_20': theme.other.color.editor.black_20,
    '--mantine-color-brand-purple': theme.other.color.brand.purple,
    '--mantine-color-brand-yellow': theme.other.color.brand.yellow,
    '--mantine-color-brand-blue': theme.other.color.brand.blue,
    '--mantine-color-brand-pink': theme.other.color.brand.pink,
    '--navbar-height': theme.other.navbarHeight.toString().concat('px'),
    '--explorer-header-height': theme.other.explorerHeaderHeight
      .toString()
      .concat('px'),
  },
  dark: {
    '--mantine-color-editor-dark': theme.other.color.editor.dark,
    '--mantine-color-editor-light': theme.other.color.editor.light,
    '--mantine-color-editor-light-alpha-50': rgba(
      theme.other.color.editor.light,
      0.5
    ),
    '--mantine-color-editor-medium': theme.other.color.editor.medium,
    '--mantine-color-editor-medium-light':
      theme.other.color.editor.medium_light,
    '--mantine-color-explorer-card': theme.other.color.explorer.card,
  },
  light: {
    '--mantine-color-editor-dark': '#e1e0e0',
    '--mantine-color-editor-light': '#f2f2f2',
    '--mantine-color-editor-light-alpha-50': rgba('#f2f2f2', 0.5),
    '--mantine-color-editor-medium': '#d9d9d9',
    '--mantine-color-editor-medium-light':
      theme.other.color.editor.medium_light,
    '--mantine-color-explorer-card': '#f3f3f3',
  },
});
