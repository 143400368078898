import { createTheme } from '@mantine/core';

export const viewerTheme = createTheme({
  fontFamily: 'Spartan, sans-serif',
  colors: {
    brandYellow: [
      '#fff6e0',
      '#ffebcd',
      '#fcd69d',
      '#f9bf6a',
      '#f7ab3e',
      '#f59f22',
      '#f59910',
      '#da8501',
      '#c37500',
      '#a96300',
    ],
    brandPurple: [
      '#f7f0fd',
      '#e8def1',
      '#cdbbdf',
      '#b295cd',
      '#9b74be',
      '#8d5fb5',
      '#8555b1',
      '#74469c',
      '#673d8c',
      '#59347c',
    ],
    brandPink: [
      '#ffe7f8',
      '#f6bde0',
      '#ec94ca',
      '#e269b3',
      '#d9409d',
      '#bf2684',
      '#961c67',
      '#6b124a',
      '#43092d',
      '#1b0013',
    ],
    instructionGreen: [
      '#f2f7f8',
      '#e7eaeb',
      '#cad5d7',
      '#a9bfc2',
      '#8eacb1',
      '#7ca0a6',
      '#729ba1',
      '#60878c',
      '#52787e',
      '#3f686e',
    ],
  },
  primaryColor: 'brandYellow',
  breakpoints: {
    xs: '48em',
    sm: '62em',
    md: '75em',
    lg: '88em',
    xl: '110em',
  },
  other: {
    navbarHeight: 40,
    explorerHeaderHeight: 70,
    color: {
      brand: {
        purple: '#6B4092',
        yellow: '#F59C1B',
        blue: '#444697',
        pink: '#C22786',
      },
      editor: {
        light: '#392E3D',
        medium: '#2F2533',
        medium_light: '#5c565f',
        dark: '#251E29',
        white_10: '#FFFFFF1A',
        white_20: '#FFFFFF33',
        black_10: '#0000001A',
        black_20: '#00000033',
      },
      explorer: {
        card: '#272429',
        instruction: '#3f686e',
      },
    },
  },
});
